<template>
  <div class="ms_footer overflow-hidden">
    {{ $t('footer.titolo') }}
  </div>
</template>

<script>
export default {
  name: 'Footer-',
}
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');

.ms_footer{
  font-family: 'Playball', cursive;
  background-color: green;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
}

</style>
