<template>
<div class="overflow-hidden">
  <router-view :key="$route.path"></router-view>
</div>
</template>

<script>
export default {
  name: "Main-",
}
</script>

<style>

</style>