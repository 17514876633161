<template>
  <div id="contatti" class="footer overflow-hidden">
    <img src="../assets/stella alpina new orange.svg" alt="">
    <div class="container ms_containerlg text-center text-xl-left">
      <div class="ms_container">
        <div class="ms_blocco-sinistra">
          <div class="ms_title d-flex">
            {{ $t('contatti.contattaci') }}
          </div>
          <div class="ms_logo mt-5">
            Chalet Costa Verde
          </div>
          <div class="ms_via">
            {{ $t('via') }}
          </div>
          <div class="ms_mail ms_contatto">
            <a href="mailto:chaletcostaverdelivigno@gmail.com">
              <i class="fas fa-envelope"></i>
              chaletcostaverdelivigno@gmail.com
            </a>
          </div>
          <div class="ms_telefono ms_contatto">
            <a href="tel:+393408418110">
              <i class="fas fa-phone"></i>
              (+39) 3408418110
            </a>
          </div>
          <div class="ms_telefono ms_contatto">
            <a href="tel:+393485653878">
              <i class="fas fa-phone"></i>
              (+39) 3485653878
            </a>
          </div>
          <!-- <div class="ms_facebook ms_contatto">
            <a href="https://www.facebook.com/pages/Chalet-Costa-Verde-Livigno/308811242516212" target="_blank">
              <i class="fa-brands fa-facebook"></i>
              Facebook
            </a>
          </div> -->
          <div class="ms_firma d-none d-xl-block">
            {{ $t('famiglia') }}
          </div>
        </div>
        <div class="ms_blocco-destra">
          <div class="ms_title position-relative text-left mb-5">
            {{ $t('contatti.dove') }}
          </div>
          <div class="ms_mappa mb-5">
            <iframe v-if="this.$i18n.locale == 'it'" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1585.1980530106498!2d10.145348399997008!3d46.545827160928575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836debac7f8597%3A0x4ad97520be44a46c!2sChalet%20Costa%20Verde!5e0!3m2!1sit!2sit!4v1660125093437!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe v-if="this.$i18n.locale == 'en'" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1585.1980530106498!2d10.145348399997008!3d46.545827160928575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836debac7f8597%3A0x4ad97520be44a46c!2sChalet%20Costa%20Verde!5e0!3m2!1sit!2sit!4v1660125093437!5m2!1sen!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <iframe v-if="this.$i18n.locale == 'de'" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1585.1980530106498!2d10.145348399997008!3d46.545827160928575!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836debac7f8597%3A0x4ad97520be44a46c!2sChalet%20Costa%20Verde!5e0!3m2!1sit!2sit!4v1660125093437!5m2!1sde!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <!-- <iframe src="https://www.google.com/maps/d/embed?mid=10HaJn0qnCDCQMuOCQwQqI-xL7SZNVig&ehbc=2E312F" width="640" height="480"></iframe> -->
            <!-- <div>
              Via Pemont, 362a, 23030 Livigno SO
            </div> -->
          </div>
        </div>
      </div>
      <div class="ms_firma1 d-block d-xl-none text-center">
        {{ $t('famiglia') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contatti-',
}
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');

.footer{
  background-color: #f7f4f0;
  padding-top: 20px;
  position: relative;
}

img{
  position: absolute;
  top: -250px;
  left: -500px;
  height: 1500px;
  transform: rotate(20deg);
  opacity: 0.3;
}

.ms_container{
  display: flex;
  align-items: center;
  margin: 50px 0 0;
  position: relative;
}

.ms_blocco-sinistra, .ms_blocco-destra{
  width: 50%;
  height: 100%;
}

.ms_title{
  position: absolute;
  top: 0;
  font-family: 'Sansita Swashed', cursive;
  font-size: 25px;
  text-decoration: underline;
}

.ms_via{
  font-style: italic;
  font-family: 'Roboto Slab', serif;
}

.ms_telefono, .ms_mail{
  font-family: 'Roboto Slab', serif;
}

.ms_contatto{
  margin: 20px 0;
}

.ms_mappa{
  width: 100%;
}

iframe{
  width: 100%;
}

.ms_firma{
  /* font-family: 'Playball', cursive; */
  font-family: 'Parisienne', cursive;
  font-size: 60px;
  margin-top: 100px;
}

.ms_firma1{
  /* font-family: 'Playball', cursive; */
  font-family: 'Parisienne', cursive;
  font-size: 60px;
}

.ms_logo {
  font-family: "Pinyon Script", cursive;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(43, 141, 43);
}

i{
  color: rgb(57, 185, 57);
}

a{
  color: black;
  font-size: 20px;
}

a:hover{
  color: rgb(43, 141, 43);
  text-decoration: none;
}

@media only screen and (max-width: 1200px) {
  .ms_container{
    display: block;
    margin-top: 0;
  }

  .ms_blocco-sinistra, .ms_blocco-destra{
    width: 100%;
  }

  .ms_blocco-sinistra{
    padding-top: 30px;
  }
}
@media only screen and (min-width: 1700px) {
  .footer{
  min-height: 100vh;
  }

  .ms_containerlg{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, - 50%);
  }
}
</style>
