<template>
<div class="mt-5">
  <b-carousel
    id="carousel-1"
    fade
    v-model="slide"
    :interval="4000"
    controls
    indicators
    background="#ababab"
    img-width="1024"
    img-height="480"
    style="text-shadow: 1px 1px 2px #333;">

    <!-- <a href="#" class="ms_scorrigiu">
      <div class="ms_freccia ms_freccia1"></div>
      <div class="ms_freccia ms_freccia2"></div>
    </a> -->
    
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/realpemont.jpg')"
          alt="image slot"
          id="img1"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/fornopizze4.jpg')"
          alt="image slot"
          id="img2"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/fotofam1.jpg')"
          alt="image slot"
          id="img3"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/fiorirp.jpg')"
          alt="image slot"
          id="img4"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/vinorp.jpg')"
          alt="image slot"
          id="img5"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/terrazza3.jpg')"
          alt="image slot"
          id="img6"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/salarp1.jpg')"
          alt="image slot"
          id="img7"
        >
      </template>
    </b-carousel-slide>
    <b-carousel-slide>
      <template #img>
        <img
          class="d-block img-fluid w-100"
          width="1024"
          height="480"
          :src="require('../assets/pinguini2.jpg')"
          alt="image slot"
          id="img7"
        >
      </template>
    </b-carousel-slide>
  </b-carousel>
  <div class="ms_container1">
    <div class="ms_container container">
      <div class="text-center">
        <div class="ms_subtitle">
          <!-- {{ $t('ristorante.titolo') }} -->
        </div>
        <div class="ms_title">
          <span class="ms_real">
            <span class="char1">R</span>
            <span class="char2">E</span>
            <span class="char3">A</span>
            <span class="char4">L</span>
          </span>
          <div class="ms_corona">
            <i class="fas fa-crown"></i>
          </div>
          <div class="ms_pemont">
            Pemont
          </div>
        </div>
      </div>
      <div class="ms_cardtelefono text-center">
        <div class="ms_telefono">
          <a href="tel:+393485653878" class="ms_numero">
            <i class="fas fa-phone"></i>
            (+39) 3485653878
          </a>
        </div>
      </div>
      <div class="ms_cardforno text-center">
        <div class="ms_forno">
          {{ $t('ristorante.forno') }}
        </div>
      </div>
    </div>
  </div>

  <div class="container ms_container">
    <div class="ms_descrizione text-center">
        {{ $t('ristorante.descrizione') }}
      <br>
        {{ $t('ristorante.descrizione2') }}
    </div>

    <div class="ms_ptitle">
      {{ $t('ristorante.titolopiatti') }}
    </div>
    <div class="ms_psubtitle text-center">
      <!-- {{ $t('ristorante.sottotitolopiatti') }} -->
    </div>
  </div>

  <div class="ms_row row row-cols-1 row-cols-md-2 row-cols-lg-6">
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto0">
        <img src="../assets/bresaolasciatt.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description1" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto1') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto1') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description1 }" @click="description1 = !description1" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto2">
        <img src="../assets/pizzocheri.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description2" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto2') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto2') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description2 }" @click="description2 = !description2" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto3">
        <img src="../assets/gnocchilivigno.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description3" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto3') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto3') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description3 }" @click="description3 = !description3" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto4">
        <img src="../assets/calzone.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description4" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto4') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto4') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description4 }" @click="description4 = !description4" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto5">
        <img src="../assets/tagliatellerp.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description5" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto5') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto5') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description5 }" @click="description5 = !description5" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto6">
        <img src="../assets/costata.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description6" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto6') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto6') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description6 }" @click="description6 = !description6" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto7">
        <img src="../assets/grigliatamista.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description7" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto7') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto7') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description7 }" @click="description7 = !description7" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto8">
        <img src="../assets/polenta salmi.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description8" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto8') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto8') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description8 }" @click="description8 = !description8" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto9">
        <img src="../assets/pizzarp.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description9" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto9') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto9') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description9 }" @click="description9 = !description9" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto10">
        <img src="../assets/pizzavaltellina.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description10" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto10') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto10') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description10 }" @click="description10 = !description10" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto11">
        <img src="../assets/polenta funghi.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description11" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto11') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto11') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description11 }" @click="description11 = !description11" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto12">
        <img src="../assets/strudel.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description12" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto12') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto12') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description12 }" @click="description12 = !description12" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto13">
        <img src="../assets/gelatofb.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description13" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto13') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto13') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description13 }" @click="description13 = !description13" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto14">
        <img src="../assets/tiramisu.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description14" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto14') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto14') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description14 }" @click="description14 = !description14" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto15">
        <img src="../assets/profitterol.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description15" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto15') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto15') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description15 }" @click="description15 = !description15" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3">
      <div class="ms_imgpiatto ms_imgpiatto16">
        <img src="../assets/panna-cotta.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description16" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto16') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto16') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description16 }" @click="description16 = !description16" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto17">
        <img src="../assets/affogato.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description17" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto17') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto17') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description17 }" @click="description17 = !description17" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
    <div class="col ms_cardpiatto pr-1 mb-3 d-none d-lg-flex">
      <div class="ms_imgpiatto ms_imgpiatto18">
        <img src="../assets/sorbetto.jpg" alt="">
      </div>
      <transition name="slide">
        <div v-if="description18" class="ms_descrizionepiatto text-center text-md-left">
          <div class="ms_descrizionetesto">
            <div class="ms_tpiatto">
              {{ $t('ristorante.piatto18') }}
            </div>
            <div class="ms_spiatto">
              {{ $t('ristorante.descrizionepiatto18') }}
            </div>
          </div>
        </div>
      </transition>
      <div v-bind:class="{ active: description18 }" @click="description18 = !description18" class="ms_pulsante d-flex">
        <i class="far fa-times-circle"></i>
      </div>
    </div>
  </div>
  <div class="row d-block d-md-flex overflow-hidden m-0">
    <div class="col p-0">
      <div class="ms_contatti">
        <div class="ms_tcontatti">
          <i class="fas fa-address-book"></i>
          {{ $t('ristorante.contatti') }}
        </div>
        <div class="ms_telefono2">
          <a href="tel:+393485653878">
            <i class="fas fa-phone"></i>
            (+39) 348 56 53 878
          </a>
        </div>
        <!-- <div class="ms_mail">
          <a href="mailto:info@chaletcostaverde.it">
            <i class="fas fa-envelope"></i>
            info@chaletcostaverde.it
          </a>
        </div> -->
      </div>
      <div class="ms_dove">
        <div class="ms_tdove">
          <i class="fas fa-map-marker-alt"></i>
          {{ $t('ristorante.dovesiamo') }}
        </div>
        <div class="ms_via">
          <a href="https://www.google.com/maps/place/Real+Pemont/@46.5462835,10.1432697,17z/data=!3m1!4b1!4m5!3m4!1s0x47836debb35bc419:0x39a4730d749e78a!8m2!3d46.5462766!4d10.1454567" target="_blank">
            Via Pemont, 391 <br>
            23041 Livigno SO
          </a>
        </div>
      </div>
    </div>
    <div class="ms_orario col">
      <div class="ms_torario">
        <i class="fas fa-clock"></i>
        {{ $t('ristorante.apertura') }}
      </div>
      <div class="pb-2">
        {{ $t('ristorante.aperturacucina') }} <br>
        {{ $t('ristorante.dalle') }} 12:00 {{ $t('ristorante.alle') }} 14:00 {{ $t('ristorante.e') }} <br>
        {{ $t('ristorante.dalle') }} 18:00 {{ $t('ristorante.alle') }} 22:00 <br>
        <!-- {{ $t('ristorante.chiusura') }} -->
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Restaurant-',
  data() {
      return {
        description1: false,
        description2: false,
        description3: false,
        description4: false,
        description5: false,
        description6: false,
        description7: false,
        description8: false,
        description9: false,
        description10: false,
        description11: false,
        description12: false,
        description13: false,
        description14: false,
        description15: false,
        description16: false,
        description17: false,
        description18: false,
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
}; 


</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital@1&display=swap');

.ms_scorrigiu{
  position: absolute;
  left: 50%;
  top: 90%;
}

.ms_freccia{
  transform: translateY(0%) rotate(45deg);
  opacity: 0;
}

.ms_freccia1{
  width: 2em; 
  height: 2em; 
  background-color: transparent; 
  z-index: 80; 
  bottom: 25px; 
  position: absolute; 
  border-width: 0 0.25em 0.25em 0; 
  border-style: solid; 
  border-color: antiquewhite; 
  animation: scrolldown 1.2s ease-in-out infinite 0.15s;
}

.ms_freccia2{
  width: 2em; 
  height: 2em; 
  background-color: transparent; z-index: 80; 
  bottom: 40px; 
  position: absolute; 
  border-width: 0 0.25em 0.25em 0; 
  border-style: solid; 
  border-color: rgb(250, 191, 114); 
  animation: scrolldown 1.2s ease-in-out infinite;
}

img{
  height: 570px;
  object-fit: cover;
}

.ms_container{
  position: relative;
}

.ms_title{
  font-family: 'Alex Brush', cursive;
  height: 100px;
  padding-bottom: 20px;
}

.ms_subtitle{
  font-family: 'Sansita Swashed', cursive;
  font-size: 30px;
  margin-bottom: 50px;
  height: 45px;
}

.ms_real{
  font-family: 'Bree Serif', serif;
}

.ms_real span {
  font-size: 40px;
  height: 150px;
  position: absolute;
  width: 20px;
  left: 0;
  top: 40px;
  transform-origin: bottom center;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.char1 { transform: rotate(-30deg); }
.char2 { transform: rotate(-10deg); }
.char3 { transform: rotate(10deg); }
.char4 { transform: rotate(30deg); }

.ms_corona{
  padding-top: 18px;
  margin: 0 auto;
}

.ms_pemont{
  line-height: 50px;
  font-size: 80px;
  margin-left: -30px;
}

.ms_descrizione{
  font-family: 'Roboto Slab', serif;
  font-size: 20px;
  margin: 30px 0;
}

.ms_telefono a{
  width: 100%;
  height: 100%;
  font-size: 25px;
  color: black;
}

.ms_forno{
  font-family: 'Roboto Slab', serif;
  font-size: 30px;
  background-image: url("../assets/wood.jpg");
  background-size: cover;
  width: 300px;
  height: 50px;
  position: relative;
  border-radius: 20px;
  /* border: 3px solid black; */
  line-height: 40px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  line-height: 50px;
}

.ms_cardtelefono{
  position: absolute;
  top: 100px;
  right: 0px;
}

.ms_cardforno{
  position: absolute;
  top: 100px;
  left: 0px;
}

.ms_telefono{
  font-family: 'Roboto Slab', serif;
  background-image: url("../assets/wood.jpg");
  background-size: cover;
  background-color: rgb(133, 106, 73);
  width: 300px;
  height: 50px;
  position: relative;
  border-radius: 20px;
  line-height: 50px;
  color: rgb(255, 255, 255);
  font-weight: bold;
  a{
    font-size: 30px;
    color: white;
  }
}

.ms_telefono:hover{
  background-image: unset;
  background-color: #deac67;
  color: white;
  a{
    color: white;
  }
}

.ms_numero{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ms_row{
  margin: 0 -3px 0 -14px;
}

.ms_ptitle{
  /* border-bottom: 1px solid #deac67; */
  /* width: 150px; */
  margin: 0 auto;
  text-align: center;
  font-family: 'Roboto Slab', serif;
  font-size: 28px;
  font-weight: bold;
  color: #deac67;
  text-decoration: underline;
}

.ms_psubtitle{
  margin-bottom: 10px;
  font-family: 'Roboto Slab', serif;
  font-size: 25px;
}

.ms_cardpiatto{
  width: 200px;
  /* height: 500px; */
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.ms_imgpiatto{
  height: 300px;
  width: 100%;
  background-color: green;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 0 10px 10px 0;
  }
}

/* .ms_imgpiatto2{
  width: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: -35px;
    object-position: center;
  }
}

.ms_imgpiatto11{
  width: 100%;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 0 -70px;
  }
} */

.ms_descrizionepiatto{
  width: 96%;
  height: 100%;
  position: absolute;
  top: 0;
  /* background-color: rgb(245, 222, 179); */
  background-image: url("../assets/foglio2.jpg");
  background-size: cover;
  /* border: 2px solid #deac67; */
  overflow: hidden;
}

.ms_descrizionetesto{
  width: 90%;
  padding: 50px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ms_tpiatto{
  font-size: 50px;
  margin-bottom: 10px;
  font-family: 'Roboto Slab', serif;
}

.ms_pulsante{
  position: absolute;
  color: white;
  top: 10px;
  right: 10px;
  font-size: 40px;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  background-color: rgb(250, 191, 114);
  cursor: pointer;
  

  &.active {
    .fa-times-circle {
      transform: rotate(90deg);
    }
  }
}

.fa-times-circle {
  transform: rotate(135deg);
}

.ms_contatti, .ms_dove{
  background-color: #deac67;
  color: black;
  padding: 0 20px;
  font-family: 'Roboto Slab', serif;
  i, a{
    color: black;
  }
}

.ms_dove{
  padding-bottom: 15px;
}

.ms_orario{
  background-color: rgb(76, 67, 67);
  color: white;
  padding: 0 20px;
  font-family: 'Roboto Slab', serif;
}

.ms_tcontatti, .ms_tdove, .ms_torario{
  padding: 10px 0;
  font-size: 20px;
}

.ms_telefono2{
  border-bottom: 1px solid black;
  padding-bottom: 15px;
}

/* #img1{
  object-position: center -500px;
}

#img2{
  object-position: center -400px;
}

#img3{
  object-position: center -150px;
}

#img4{
  object-position: center -600px;
}

#img5{
  object-position: center -1600px;
}

#img6{
  object-position: center -250px;
} */

//animazione descrizione portate

/* .slide-enter-active {
  animation: slide-in 0.5s;
}
.slide-leave-active {
  animation: slide-in reverse 0.5s;
} */
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}



@keyframes scrolldown {
  0%{
      transform: translateY(20%) rotate(45deg);
      opacity: 1;
  }
  50%{
      transform: translateY(0%) rotate(45deg);
      opacity: 0.7;
  }
  100%{
      transform: translateY(20%) rotate(45deg);
      opacity: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .ms_real span {
    top: 70px;
  }

  .ms_subtitle{
    margin-bottom: 80px;
  }
  
  .ms_cardtelefono{
    position: relative;
    top: 0px;
    margin-bottom:20px;
  }

  .ms_cardforno{
    position: relative;
    top: 0px;
    padding-bottom:20px;
  }

  .ms_forno{
    margin: 0 auto;
  }

  .ms_telefono{
    margin: 0 auto;
  }

  img{
    height: 350px;
  }

  
}

@media only screen and (max-width: 451px) {
  .ms_real span {
    top: -50px;
  }
  
  .ms_subtitle{
    height: 0;
  }
  .ms_telefono{
    width: 250px;
    a{
      font-size: 20px;
    }
  }

  .ms_forno{
    font-size: 20px;
    width: 250px;
    margin: 0 auto;
  }

  img{
    height: 250px;
  }
  /* .ms_cardpiatto{
    height: 270px;
  } */
  .ms_tpiatto{
    font-size: 35px;
  }
}

@media only screen and (min-width: 768px) {
  .ms_imgpiatto{
    height: 300px;
  }
}
@media only screen and (min-width: 993px) {
  .ms_descrizionetesto{
    padding: 0px;
  }
  .ms_tpiatto{
    font-size: 25px;
  }
}
@media only screen and (max-width: 281px) {
  .ms_descrizionetesto{
    padding: 0;
  }
}
</style>
