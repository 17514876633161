<template>
  <div class="ms_container  position-relative">
    <div class="d-block d-lg-none">

      <div class="ms_colpemontmd pb-3">
        <div class="container">
          <div class="">
            <!-- col ms_colpemont ms_none" v-bind:class="{ isshow: showpemont } -->
            <div class="ms_apptitle ms_apptitle1 position-relative text-center">
              {{ $t('appartamenti.appartamentoPemont') }}
            </div>
            <div class="ms_appluogo ms_appluogo1 text-center">
              <i class="fa-solid fa-location-dot"></i>
                ({{ $t('viapemont') }})
            </div>
            <div class="ms_carosellomd">
              <carousel :autoplay="true" :loop="true" :paginationEnabled="false"  :navigationEnabled="true" :perPage="1">
                <slide>
                  <img src="../assets/appartamento-pemont.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/lettopemont.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/nottepemont.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/appartamento-pemont5.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/bagnopemont.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/estatepemont.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/depositosci1.jpg" alt="">
                </slide>
              </carousel>
            </div>
            <div class="ms_appdescrizione mt-3 mb-5">
              {{ $t('appartamenti.descrizionePemont') }}
            </div>
            <div class="ms_servizi">
              <div class="ms_servizio"><i class="fas fa-skiing"></i> {{ $t('appartamenti.servizio1Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-bed"></i> {{ $t('appartamenti.servizio2Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-shower"></i> {{ $t('appartamenti.servizio3Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-window-maximize"></i> {{ $t('appartamenti.servizio4Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-soap"></i> {{ $t('appartamenti.servizio5Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-coffee"></i> {{ $t('appartamenti.servizio6Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-tv"></i> {{ $t('appartamenti.servizio7Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-paw"></i> {{ $t('appartamenti.servizio8Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-wifi"></i> {{ $t('appartamenti.servizio9Pemont') }}</div>
              <div class="ms_servizio"><i class="fas fa-parking"></i> {{ $t('appartamenti.servizio10Pemont') }}</div>
            </div>
            <div class="ms_posizione">
              <div class="ms_contatti ms_contatti1 text-center">
                <div class="ms_telefono">
                  <a href="tel:+393408418110">
                    <i class="fa-solid fa-phone"></i>
                    <br>
                    (+39) 3408418110
                  </a>
                </div>
                <div class="ms_mail">
                  <a href="mailto:chaletcostaverdelivigno@gmail.com">
                    <i class="fa-solid fa-envelope"></i>
                      chaletcostaverdelivigno
                      <br>
                      @gmail.com
                  </a>

                </div>
              </div>
              <iframe v-if="this.$i18n.locale == 'it'" id="iframe1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1295.0271282206263!2d10.144705956306543!3d46.54636196454386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836d5b37a21f41%3A0x9cf3fc26d48f2735!2sVia%20Pemont%2C%20391A%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661504396830!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <iframe v-if="this.$i18n.locale == 'en'" id="iframe1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1295.0271282206263!2d10.144705956306543!3d46.54636196454386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836d5b37a21f41%3A0x9cf3fc26d48f2735!2sVia%20Pemont%2C%20391A%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661504396830!5m2!1sen!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <iframe v-if="this.$i18n.locale == 'de'" id="iframe1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1295.0271282206263!2d10.144705956306543!3d46.54636196454386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836d5b37a21f41%3A0x9cf3fc26d48f2735!2sVia%20Pemont%2C%20391A%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661504396830!5m2!1sde!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>

      <div class="ms_colcantonmd pb-3">
        <div class="container">
          <div class="">
            <!-- col ms_colcanton mt-3 m-lg-0 ms_none" v-bind:class="{ isshow: showcanton } -->
            <div class="ms_lineaorizzontale d-block d-lg-none mb-3"></div>
            <div class="ms_apptitle ms_apptitle2 text-center">
              {{ $t('appartamenti.appartamentoCanton') }}
            </div>
            <div class="ms_appluogo ms_appluogo2 text-center">
              <i class="fa-solid fa-location-dot"></i>
              ({{ $t('viacanton') }})
            </div>
            <div class="ms_carosellomd">
              <carousel :autoplay="true" :loop="true" :paginationEnabled="false"  :navigationEnabled="true" :perPage="1">
                <slide>
                  <img src="../assets/canton2.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/canton1.jpg" alt="">
                </slide>
                <slide class="img1">
                  <img src="../assets/soggiornocanton.jpg" alt="">
                </slide>
                <slide class="img2">
                  <img src="../assets/bagnocanton.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/tramonto.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/cantonsfondo.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/fioricanton.jpg" alt="">
                </slide>
                <slide>
                  <img src="../assets/livignocanton.jpg" alt="">
                </slide>
              </carousel>
            </div>
            <div class="ms_appdescrizione mt-3 mb-5">
              {{ $t('appartamenti.descrizioneCanton') }}
            </div>
            <div class="ms_servizi">
              <div class="ms_servizio2"><i class="fas fa-skiing"></i> {{ $t('appartamenti.servizio1Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-bed"></i> {{ $t('appartamenti.servizio2Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-shower"></i> {{ $t('appartamenti.servizio3Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-window-maximize"></i> {{ $t('appartamenti.servizio4Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-soap"></i> {{ $t('appartamenti.servizio5Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-coffee"></i> {{ $t('appartamenti.servizio6Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-tv"></i> {{ $t('appartamenti.servizio7Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-paw"></i> {{ $t('appartamenti.servizio8Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-wifi"></i> {{ $t('appartamenti.servizio9Canton') }}</div>
              <div class="ms_servizio2"><i class="fas fa-parking"></i> {{ $t('appartamenti.servizio10Canton') }}</div>
            </div>
            <div class="ms_posizione">
              <div class="ms_contatti ms_contatti2 text-center">
                <div class="ms_telefono">
                  <a href="tel:+393408418110">
                    <i class="fa-solid fa-phone"></i>
                    <br>
                    (+39) 3408418110
                  </a>
                </div>
                <div class="ms_mail">
                  <a href="mailto:chaletcostaverdelivigno@gmail.com">
                    <i class="fa-solid fa-envelope"></i>
                    chaletcostaverdelivigno
                    <br>
                    @gmail.com
                  </a>

                </div>
              </div>
              <iframe v-if="this.$i18n.locale == 'it'" id="iframe2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.8908268308198!2d10.133212644556151!3d46.552168540890754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836dddcf354d9b%3A0x2bcf723c8b687aae!2sVia%20Canton%2C%20304%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661011883595!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <iframe v-if="this.$i18n.locale == 'en'" id="iframe2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.8908268308198!2d10.133212644556151!3d46.552168540890754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836dddcf354d9b%3A0x2bcf723c8b687aae!2sVia%20Canton%2C%20304%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661011883595!5m2!1sen!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              <iframe v-if="this.$i18n.locale == 'de'" id="iframe2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.8908268308198!2d10.133212644556151!3d46.552168540890754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836dddcf354d9b%3A0x2bcf723c8b687aae!2sVia%20Canton%2C%20304%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661011883595!5m2!1sde!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>



    <!-- <div class="ms_title text-center">
      I nostri appartamenti
    </div>
    <div class="ms_descrizione text-center">
        Il calore dell’accoglienza, la passione dell’ospitalità, l’incanto della natura che ci circonda fanno della nostra casa un’atmosfera indimenticabile nella splendida cornice delle alpi.
      <br>
        Saremmo lieti di darvi il benvenuto.
    </div> -->
    <div class="ms_homeapp row text-center row-cols-1 row-cols-lg-2 d-none d-lg-flex">
      <div class="col ms_col position-relative p-0" v-bind:class="{ ms_hidden: showpemont }">
        <div class="ms_bgimage1">
          <div class="ms_overlay1">
            <div class="ms_appartamento1">
              <div class="ms_appartamento ms_appartamentopemont position-absolute">
                <div @click="showpemont = true">
                  {{ $t('appartamenti.appartamentoPemont') }}
                  <div class="ms_via">
                    ({{ $t('viapemont') }})
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
        <div class="arrow-1 d-flex d-lg-none"></div>
      </div>
      <div class="col ms_col position-relative p-0" v-bind:class="{ ms_hidden: showcanton }">
        <div class="ms_bgimage2">
          <div class="ms_overlay2">
            <div class="ms_appartamento2">
              <div class="ms_appartamento ms_appartamentocanton position-absolute">
                <div @click="showcanton = true">
                  {{ $t('appartamenti.appartamentoCanton') }}
                  <div class="ms_via">
                    ({{ $t('viacanton') }})
                  </div>
                </div>    
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  







    <div class="row row-cols-1 row-cols-lg-2 d-none d-lg-block">
      <div class="col ms_colpemont ms_none" v-bind:class="{ isshow: showpemont }">
        <div class="ms_close ms_close1" @click="showpemont = false">
          <i class="far fa-times-circle"></i>
        </div>
        <div class="ms_apptitle ms_apptitle1 position-relative text-center">
          {{ $t('appartamenti.appartamentoPemont') }}
        </div>
        <div class="ms_appluogo ms_appluogo1 text-center">
          <i class="fa-solid fa-location-dot"></i>
            ({{ $t('viapemont') }})
        </div>
        <div class="ms_carosello">
          <carousel :autoplay="true" :loop="true" :paginationEnabled="false"  :navigationEnabled="true" :perPage="1">
            <slide>
              <img src="../assets/appartamento-pemont.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/lettopemont.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/nottepemont.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/appartamento-pemont5.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/bagnopemont.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/estatepemont.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/depositosci1.jpg" alt="">
            </slide>
          </carousel>
        </div>
        <div class="ms_appdescrizione mt-3 mb-5">
          {{ $t('appartamenti.descrizionePemont') }}
        </div>
        <div class="ms_servizi">
          <div class="ms_servizio"><i class="fas fa-skiing"></i> {{ $t('appartamenti.servizio1Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-bed"></i> {{ $t('appartamenti.servizio2Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-shower"></i> {{ $t('appartamenti.servizio3Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-window-maximize"></i> {{ $t('appartamenti.servizio4Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-soap"></i> {{ $t('appartamenti.servizio5Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-coffee"></i> {{ $t('appartamenti.servizio6Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-tv"></i> {{ $t('appartamenti.servizio7Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-paw"></i> {{ $t('appartamenti.servizio8Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-wifi"></i> {{ $t('appartamenti.servizio9Pemont') }}</div>
          <div class="ms_servizio"><i class="fas fa-parking"></i> {{ $t('appartamenti.servizio10Pemont') }}</div>
        </div>
        <div class="ms_posizione">
          <div class="ms_contatti ms_contatti1 text-center">
            <div class="ms_telefono">
              <a href="tel:+393408418110">
                <i class="fa-solid fa-phone"></i>
                <br>
                (+39) 3408418110
              </a>
            </div>
            <div class="ms_mail">
              <a href="mailto:chaletcostaverdelivigno@gmail.com">
                <i class="fa-solid fa-envelope"></i>
                  chaletcostaverdelivigno
                  <br>
                  @gmail.com
              </a>

            </div>
          </div>
          <iframe v-if="this.$i18n.locale == 'it'" id="iframe1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1295.0271282206263!2d10.144705956306543!3d46.54636196454386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836d5b37a21f41%3A0x9cf3fc26d48f2735!2sVia%20Pemont%2C%20391A%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661504396830!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <iframe v-if="this.$i18n.locale == 'en'" id="iframe1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1295.0271282206263!2d10.144705956306543!3d46.54636196454386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836d5b37a21f41%3A0x9cf3fc26d48f2735!2sVia%20Pemont%2C%20391A%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661504396830!5m2!1sen!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <iframe v-if="this.$i18n.locale == 'de'" id="iframe1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1295.0271282206263!2d10.144705956306543!3d46.54636196454386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836d5b37a21f41%3A0x9cf3fc26d48f2735!2sVia%20Pemont%2C%20391A%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661504396830!5m2!1sde!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>


      <div class="col ms_colcanton mt-3 m-lg-0 ms_none" v-bind:class="{ isshow: showcanton }">
        <div class="ms_close ms_close2" @click="showcanton = false">
          <i class="far fa-times-circle"></i>
        </div>
        <div class="ms_lineaorizzontale d-block d-lg-none mb-3"></div>
        <div class="ms_apptitle ms_apptitle2 text-center">
          {{ $t('appartamenti.appartamentoCanton') }}
        </div>
        <div class="ms_appluogo ms_appluogo2 text-center">
          <i class="fa-solid fa-location-dot"></i>
          ({{ $t('viacanton') }})
        </div>
        <div class="ms_carosello">
          <carousel :autoplay="true" :loop="true" :paginationEnabled="false"  :navigationEnabled="true" :perPage="1">
            <slide>
              <img src="../assets/canton2.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/canton1.jpg" alt="">
            </slide>
            <slide class="img1">
              <img src="../assets/soggiornocanton.jpg" alt="">
            </slide>
            <slide class="img2">
              <img src="../assets/bagnocanton.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/tramonto.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/cantonsfondo.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/fioricanton.jpg" alt="">
            </slide>
            <slide>
              <img src="../assets/livignocanton.jpg" alt="">
            </slide>
          </carousel>
        </div>
        <div class="ms_appdescrizione mt-3 mb-5">
          {{ $t('appartamenti.descrizioneCanton') }}
        </div>
        <div class="ms_servizi">
          <div class="ms_servizio2"><i class="fas fa-skiing"></i> {{ $t('appartamenti.servizio1Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-bed"></i> {{ $t('appartamenti.servizio2Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-shower"></i> {{ $t('appartamenti.servizio3Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-window-maximize"></i> {{ $t('appartamenti.servizio4Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-soap"></i> {{ $t('appartamenti.servizio5Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-coffee"></i> {{ $t('appartamenti.servizio6Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-tv"></i> {{ $t('appartamenti.servizio7Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-paw"></i> {{ $t('appartamenti.servizio8Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-wifi"></i> {{ $t('appartamenti.servizio9Canton') }}</div>
          <div class="ms_servizio2"><i class="fas fa-parking"></i> {{ $t('appartamenti.servizio10Canton') }}</div>
        </div>
        <div class="ms_posizione">
          <div class="ms_contatti ms_contatti2 text-center">
            <div class="ms_telefono">
              <a href="tel:+393408418110">
                <i class="fa-solid fa-phone"></i>
                <br>
                (+39) 3408418110
              </a>
            </div>
            <div class="ms_mail">
              <a href="mailto:chaletcostaverdelivigno@gmail.com">
                <i class="fa-solid fa-envelope"></i>
                chaletcostaverdelivigno
                <br>
                @gmail.com
              </a>

            </div>
          </div>
          <iframe v-if="this.$i18n.locale == 'it'" id="iframe2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.8908268308198!2d10.133212644556151!3d46.552168540890754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836dddcf354d9b%3A0x2bcf723c8b687aae!2sVia%20Canton%2C%20304%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661011883595!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <iframe v-if="this.$i18n.locale == 'en'" id="iframe2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.8908268308198!2d10.133212644556151!3d46.552168540890754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836dddcf354d9b%3A0x2bcf723c8b687aae!2sVia%20Canton%2C%20304%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661011883595!5m2!1sen!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          <iframe v-if="this.$i18n.locale == 'de'" id="iframe2" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1539.8908268308198!2d10.133212644556151!3d46.552168540890754!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47836dddcf354d9b%3A0x2bcf723c8b687aae!2sVia%20Canton%2C%20304%2C%2023030%20Livigno%20SO!5e0!3m2!1sit!2sit!4v1661011883595!5m2!1sde!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

/* import { Carousel, Slide } from 'vue-carousel'; */
export default {
  name: 'Apartment-',
  data() {
    return {
      showpemont: false,
      showcanton: false,
    }
  },
  components: {
    /* Carousel,
    Slide, */
  }
  
};

</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital@1&display=swap');

.ms_container{
  margin-top: 50px;
  overflow: scroll;
  width: 100vw;
  overflow-x: hidden;
}

/* .ms_homeapp{
  height:  calc(100vh - 100px);
  height: 800px;
} */

.ms_servizi{
  position: absolute;
  left: 30px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  margin-top: -20px;
}

.ms_servizio{
  margin-bottom: 3px;
  i{
    margin-right: 5px;
    color: rgb(213, 129, 222);
  }
}

.ms_servizio2{
  margin-bottom: 3px;
  i{
    margin-right: 5px;
    color: rgb(54, 203, 66);
  }
}

.ms_col{
  min-height: calc(100vh - 50px);
  height: 837px;
}

.ms_appartamento {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  cursor: pointer;
}

.ms_appartamentopemont{
  color: rgb(232, 196, 236);
}

.ms_appartamentocanton{
  color: rgba(81, 192, 94, 0.737);;
}

.ms_appartamento1, .ms_appartamento2{
  width: 100%;
  height: 100%;
  z-index: 3;
}

.ms_appartamento1:hover{
  background-color: unset;
  .ms_appartamentopemont{
    /* color: rgba(232, 196, 236, 0.195); */
    color: white;
  }
}

.ms_bgimage1{
  background-image: url('../assets/realpemontsfondo.jpg');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.ms_bgimage2{
  background-image: url('../assets/cantonsfondo.jpg');
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: right;
}

.ms_appartamento2:hover{
  background-color: unset;
  .ms_appartamentocanton{
    color: white;
  }
}

.ms_overlay1{
  width: 100%;
  height: 100%;
  background-color: #925e785e;
}

.ms_overlay2{
  width: 100%;
  height: 100%;
  background-color: rgba(122, 203, 85, 0.188);
}

.ms_title{
  font-family: 'Sansita Swashed', cursive;
  font-size: 30px;
  margin-bottom: 50px;
}

.ms_descrizione{
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 20px;
  margin: 30px 0;
}

.ms_appdescrizione{
  font-family: 'Roboto Slab', serif;
  text-align: center;
}

.ms_appartamento{
  font-family: 'Sansita Swashed', cursive;
  text-decoration: underline;
  font-size: 30px;
  margin-bottom: 20px;
}

.ms_via{
  font-size: 15px;
  text-decoration: none;
}

a{
  color: black;
}

.ms_colpemont{
  position: absolute;
  top: 0;
  left: 0;
  min-height:  calc(100vh - 50px);
  /* overflow: hidden; */
  background-color: rgba(232, 196, 236, 0.104);
  height: 100%;
}

.ms_colcanton{
  position: absolute;
  /* bottom: 0; */
  right: 0;
  top: 838px;
  margin-top: 0!important;
  min-height:  calc(100vh - 50px);
  /* overflow: hidden; */
  background-color: rgba(159, 203, 151, 0.106);
  height: 100%;
}

.ms_colpemontmd{
  background-color: rgba(232, 196, 236, 0.195);
}

.ms_colcantonmd{
  background-color: rgba(112, 209, 93, 0.159);
}

.ms_apptitle1, .ms_appluogo1, .ms_close1{
  font-family: 'Sansita Swashed', cursive;
  color: rgb(211, 59, 227);
}

.ms_apptitle2, .ms_appluogo2, .ms_close2{
  font-family: 'Sansita Swashed', cursive;
  color: rgb(54, 203, 66);
}

.ms_apptitle{
  font-size: 40px;
}

.ms_appluogo{
  margin-bottom: 5px;
}

.ms_close{
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 40px;
  cursor: pointer;
  z-index: 2;
}

.ms_carosello{
  width: 30vw;
  margin: 0 auto;
}

.ms_carosellomd{
  width: 90%;
  margin: 0 auto;
}

.VueCarousel-slide{
  height: 300px;
}

.img1{
  img{
    filter: brightness(1.2);
  }
}

.img2{
  img{
    object-position: 0 bottom;
  }
}

img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ms_posizione{
  position: relative;
}

.ms_contatti{
  position: absolute;
  width: 150px;
  height: 120px;
  right: 0px;
  top: -20px;
  border-radius: 15px;
  border: 2px solid black;
  a:hover{
    color: white;
    text-decoration: none;
  }
}

.ms_contatti1{
  background-color: rgb(213, 129, 222);
}

.ms_contatti2{
  background-color: rgb(54, 203, 66);
}

iframe{
  height: 250px;
  width: 50%;
  margin-left: 46%;
}

#iframe1{
  border: 5px solid rgb(211, 59, 227)!important;
}

#iframe2{
  border: 5px solid rgb(54, 203, 66)!important;
}

.ms_mail{
  font-size: 13px;
}

.ms_linea{
  border-right: 5px dashed black;
  width: 97%;
  height: 100%;
  position: absolute;
}

.ms_lineaorizzontale{
  width: 60%;
  margin: 0 auto;
  border-top: 5px dashed black;
}

.ms_saluti{
  font-size: 25px;
}

.ms_none{
  display: none;
}

.ms_hidden{
  visibility: hidden;
}

.isshow {
  display: unset;
}

//freccia giù
.arrow-1 {
  width: 100px;
  height: 30px;
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 0;
  transform: rotate(90deg);
  color: yellow;
}

.arrow-1:before {
  content: "";
  background: currentColor;
  width:15px;
  clip-path: polygon(0 10px,calc(100% - 15px) 10px,calc(100% - 15px) 0,100% 50%,calc(100% - 15px) 100%,calc(100% - 15px) calc(100% - 10px),0 calc(100% - 10px));
  animation: a1 1.5s infinite linear;
}

@keyframes a1 {
  90%,100%{flex-grow: 1}
}




@media only screen and (min-width: 992px) {
  .ms_container{
    overflow: hidden;
  }

  /* .ms_homeapp{
    height:  calc(100vh - 100px);
  } */


  .ms_appartamento1, .ms_appartamento2{
    background-color: rgb(244, 244, 241);
  }

  .ms_colpemont{
    top: 0;
  }
  
  .ms_colcanton{
    top: 0;
  }

  .ms_apptitle{
    font-size: 30px;
  }

}

/* @media only screen and (max-width: 300px) {
  .ms_carosello{
    width: 80vw;
    margin: 0 auto;
  }

  .ms_col{
    height: 886px;
  }

  .ms_colpemont{
    height: 886px;
  }

  .ms_colcanton{
    height: unset;
    top: 886px;
    margin-top: 0;
  }
} */
@media only screen and (max-width: 800px) {
  .ms_colcantonmd{
    height: 1100px;
  }
  .ms_colpemontmd{
    height: 1100px;
  }
  .ms_servizi{
      width: 40%;
    }
}
</style>
