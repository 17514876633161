<template>
  <div class="position-relative">
    <b-carousel
      id="carousel-1"
      no-hover-pause: true
      fade
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333;">

      <a href="#home" class="ms_scorrigiu">
        <div class="ms_freccia ms_freccia1"></div>
        <div class="ms_freccia ms_freccia2"></div>
      </a>
      
      

      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="require('../assets/Livigno2.jpg')"
            alt="image slot"
            id="img1"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="require('../assets/Livigno1.jpg')"
            alt="image slot"
            id="img2"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="require('../assets/fiori-montagna.jpg')"
            alt="image slot"
            id="img3"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="require('../assets/pinguini.jpg')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="require('../assets/salacv4.jpg')"
            alt="image slot"
            id="img5"
          >
        </template>
      </b-carousel-slide>
      <b-carousel-slide>
        <template #img>
          <img
            class="d-block img-fluid w-100"
            width="1024"
            height="480"
            :src="require('../assets/panorama3.jpg')"
            alt="image slot"
          >
        </template>
      </b-carousel-slide>
    </b-carousel>
    <div class="ms_stella-alpina">
      <img src="../assets/stella alpina new grey.svg" alt="" />
    </div>
  </div>
</template>


<script> 

export default {
  name: 'Header-',
  data() {
      return {
        slide: 0,
        sliding: null
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      }
    }
}; 

</script>


<style scoped lang="scss">
.ms_scorrigiu{
  position: absolute;
  left: 50%;
  top: 90%;
}

.ms_freccia{
  transform: translateY(0%) rotate(45deg);
  opacity: 0;
}

.ms_freccia1{
  width: 2em; 
  height: 2em; 
  background-color: transparent; 
  z-index: 80; 
  bottom: 25px; 
  position: absolute; 
  border-width: 0 0.25em 0.25em 0; 
  border-style: solid; 
  border-color: rgb(114, 172, 114); 
  animation: scrolldown 1.2s ease-in-out infinite 0.15s;
}

.ms_freccia2{
  width: 2em; 
  height: 2em; 
  background-color: transparent; z-index: 80; 
  bottom: 40px; 
  position: absolute; 
  border-width: 0 0.25em 0.25em 0; 
  border-style: solid; 
  border-color: rgb(255, 216, 165); 
  animation: scrolldown 1.2s ease-in-out infinite;
}

@keyframes scrolldown {
  0%{
      transform: translateY(20%) rotate(45deg);
      opacity: 1;
  }
  50%{
      transform: translateY(0%) rotate(45deg);
      opacity: 0.7;
  }
  100%{
      transform: translateY(20%) rotate(45deg);
      opacity: 1;
  }
}

img{
  height: 100%;
  object-fit: cover;
  position: relative;
  margin-top: 50px;
}

#img1{
  object-position: center;
}

#img2{
  object-position: top;
}

#img3{
  object-position: top;
}



.ms_stella-alpina {
  width: 100%;
  height: 100%;
  img {
    position: absolute;
    /* transform: rotate(-20deg); */
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

/* @media only screen and (min-width: 768px) {
  img{
    height: 60vh;
  }
} */

@media only screen and (min-width: 1200px) {
  img{
    height: unset;
    height: calc(100vh - 50px)!important;
  }
}
</style>
