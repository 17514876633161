<template>
  <div class="overflow-hidden">
    <div class="ms_navbar d-flex justify-content-center align-items-center">
      <div class="ms_left d-flex">
        <div class="ms_telefono d-none d-md-block">
          <a href="tel:+393408418110">
            <i class="fas fa-phone"></i>
            (+39) 3408418110
          </a>
        </div>
        <div class="ms_mail d-none d-xl-block">
          |
          <a href="mailto:chaletcostaverdelivigno@gmail.com">
            <i class="fas fa-envelope"></i>
            chaletcostaverdelivigno@gmail.com
          </a>
        </div>
      </div>
      <div class="ms_logo">
        <router-link :to="`/${$i18n.locale}`"> Chalet Costa Verde </router-link>
        <span class="ms_img">
          <img src="../../assets/stella alpina new.svg" alt="">
        </span>
      </div>
      <div class="ms_mappa d-none d-lg-block">
        <a href="https://www.google.com/maps/place/Chalet+Costa+Verde/@46.5462237,10.1439907,17z/data=!3m1!4b1!4m5!3m4!1s0x47836debac7f8597:0x4ad97520be44a46c!8m2!3d46.5462237!4d10.1461847" target="_blank">
          <i class="fas fa-map-marker-alt"></i>
          Via Pemont 362/A - Livigno (SO)
        </a>
      </div>

      <div id="sidemenu">
        <button
          class="sidemenu__btn"
          v-on:click="navOpen = !navOpen"
          v-bind:class="{ active: navOpen }"
        >
          <span class="top"></span>
          <span class="mid"></span>
          <span class="bottom"></span>
        </button>
        <transition name="translateX">
          <nav v-show="navOpen">
            <div class="sidemenu__wrapper">
              <ul class="sidemenu__list">
                <div class="ms_lang">
                  <a @click.prevent="setLocale('it')" @click="navOpen = !navOpen" :class="{ active : this.$i18n.locale == 'it' }" href="#">IT</a>
                  <a @click.prevent="setLocale('en')"  @click="navOpen = !navOpen" :class="{ active : this.$i18n.locale == 'en' }" href="#">EN</a>
                  <a @click.prevent="setLocale('de')" @click="navOpen = !navOpen" :class="{ active : this.$i18n.locale == 'de' }" href="#">DE</a>
                </div>
                <a href="home">
                  <li v-on:click="navOpen = !navOpen" class="sidemenu__item">
                    <router-link :to="`/${$i18n.locale}`" exact>
                      Home
                    </router-link>
                    <!-- <a href="#home" v-on:click="navOpen = !navOpen">Home</a> -->
                  </li>
                </a>
                <!-- <li class="sidemenu__item">
                  <a href="#camere" v-on:click="navOpen = !navOpen">Camere</a>
                </li> -->
                <li v-on:click="navOpen = !navOpen" class="sidemenu__item">
                  <router-link :to="`/${$i18n.locale}/Price`" exact>
                    {{ $t('nav.prezzi') }}
                  </router-link>
                  <!-- <a href="#prezzi" v-on:click="navOpen = !navOpen">Prezzi</a> -->
                </li>
                <li v-on:click="navOpen = !navOpen" class="sidemenu__item">
                  <router-link :to="`/${$i18n.locale}/Room`" exact>
                    {{ $t('nav.camere') }}
                  </router-link>
                  <!-- <a href="#ristorante" v-on:click="navOpen = !navOpen"
                    >Il nostro ristorante</a
                  > -->
                </li>
                <li v-on:click="navOpen = !navOpen" class="sidemenu__item">
                  <router-link :to="`/${$i18n.locale}/Restaurant`" exact>
                    {{ $t('nav.ristorante') }}
                  </router-link>
                  <!-- <a href="#ristorante" v-on:click="navOpen = !navOpen"
                    >Il nostro ristorante</a
                  > -->
                </li>
                <li v-on:click="navOpen = !navOpen" class="sidemenu__item">
                  <router-link :to="`/${$i18n.locale}/Apartment`" exact>
                  {{ $t('nav.appartamenti') }}
                  </router-link>
                  <!-- <a href="#appartamenti" v-on:click="navOpen = !navOpen"
                    >I nostri appartamenti</a
                  > -->
                </li>
                <li v-on:click="navOpen = !navOpen" class="sidemenu__item">
                  <router-link :to="`/${$i18n.locale}/Livigno`" exact>Livigno</router-link>
                  <!-- <a href="#livigno" v-on:click="navOpen = !navOpen">Livigno</a> -->
                </li>
                <!-- <li class="sidemenu__item">
                  <a href="#contatti" v-on:click="navOpen = !navOpen">
                    {{ $t('nav.contatti') }}
                  </a>
                </li> -->
              </ul>
            </div>
          </nav>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header-",
  data() {
    return {
      navOpen: false,
    };
  },
  methods:{
    setLocale(locale) {
      this.$i18n.locale = locale
      this.$router.push({
        params: { lang: locale}
      })
    }
  }
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Pinyon+Script&display=swap");

.ms_telefono, .ms_mail, .ms_mappa{
  font-family: 'Roboto Slab', serif;
}
//navbar
.ms_navbar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: white;
  height: 50px;
  width: 100vw;
  box-shadow: -1px -1px 10px black;
}

.ms_left {
  position: absolute;
  left: 0;
  padding-left: 40px;
  a {
    color: grey;
    text-decoration: none;
    padding: 0 10px;
  }
  a:hover {
    color: rgb(43, 141, 43);
  }
}

.ms_mappa{
  position: absolute;
  right: 0px;
  padding-right: 70px;
  a {
    color: grey;
    text-decoration: none;
    padding: 0 10px;
  }
  a:hover {
    color: rgb(43, 141, 43);
  }
}

.active:hover{
  color: white!important;
}

.ms_logo {
  font-family: "Pinyon Script", cursive;
  font-size: 2rem;
  font-weight: bold;
  color: rgb(43, 141, 43);

  a {
    color: rgb(43, 141, 43);
    text-decoration: none;
  }
}

//hamburger menu

#sidemenu {
  nav {
    width: 300px;
    //background-color: #ECEFF1;
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    height: 100%;
    box-shadow: -1px 70px 30px black;
    a{
      font-family: 'Roboto Slab', serif;
    }
  }

  .sidemenu {
    &__btn {
      display: block;
      width: 50px;
      height: 50px;
      border: none;
      z-index: 100;
      appearance: none;
      cursor: pointer;
      outline: none;
      position: fixed;
      top: 0px;
      right: 0px;
      background-color: white;

      span {
        display: block;
        width: 20px;
        height: 2px;
        margin: auto;
        background: grey;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transition: all 0.4s ease;

        &.top {
          transform: translateY(-8px);
        }

        &.bottom {
          transform: translateY(8px);
        }
      }
      &.active {
        .top {
          transform: rotate(-45deg);
        }
        .mid {
          //transform: translateX(-20px) rotate(360deg);
          opacity: 0;
        }
        .bottom {
          transform: rotate(45deg);
        }
      }
    }

    &__wrapper {
      padding-top: 50px;
    }

    &__list {
      padding-top: 50px;
      list-style: none;
      padding: 0;
      margin: 0;
    }

    &__item {
      a {
        text-decoration: none;
        line-height: 1.6em;
        font-size: 1.6em;
        padding: 0.5em;
        display: block;
        color: #333;
        transition: 0.4s ease;

        &:hover {
          background: lightgrey;
          color: white;
        }
      }
    }
  }
}

.translateX-enter {
  transform: translateX(200px);
  opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top left 0;
  transition: 0.2s ease;
}

.translateX-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

.ms_lang {
  font-family: 'Roboto Slab', serif;
  position: absolute;
  top: 10px;
  left: 0;
  padding-left: 5px;
  font-weight: bold;
  a {
    color: grey;
    text-decoration: none;
    padding: 0 10px;
  }
  a:hover {
    color: rgb(43, 141, 43);
  }
}

.ms_img{
  width: 70px;
  height: 70px;
  overflow: hidden;
  margin-left: -15px;
}

img{
  width: 70px;
  height: 70px;
}

a:hover{
  text-decoration: none;
}

a.active{
  background-color: rgb(43, 141, 43);
  color: white;
}

@media only screen and (max-width: 300px) {
  .ms_logo{
    font-size: 26px;
  }

  .ms_img{
    display: none;
  }

  nav {
    width: 250px!important;
  }
}
</style>
