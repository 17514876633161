<template>
<div class="ms_overlay">
  <div id="home" class="ms_container py-5 container d-block d-lg-flex align-items-center position--relative">
    <div class="ms_benvenuti">
      <div class="ms_stella-alpina">
        <img src="../assets/stella alpina new orange.svg" alt="" />
      </div>
      <div class="row row-cols-1 row-cols-lg-2">
        <div class="col" data-aos="fade-right" data-aos-duration="3000">
          <p class="ms_scritta">{{ $t('benvenuti.titolo') }}</p>
          <div class="ms_logo text-center">Chalet Costa Verde</div>
          <p class="ms_descrizione-hotel text-center">{{ $t('benvenuti.contenuto') }}</p>
          <p class="ms_descrizione-hotel text-center">{{ $t('benvenuti.contenuto2') }}</p>
          <p class="ms_descrizione-hotel text-center">{{ $t('benvenuti.contenuto3') }}</p>
          <p class="ms_descrizione-hotel text-center">{{ $t('benvenuti.contenuto4') }}</p>
          <p class="ms_descrizione-hotel text-center">{{ $t('benvenuti.contenuto5') }}</p>
        </div>

        <div
          class="ms_none d-none d-lg-block"
          data-aos="fade-left"
          data-aos-duration="3000"
        >
          <div class="ms_image ms_image1">
            <img src="../assets/benvenuti1.jpg" alt="" />
          </div>
          <div class="ms_image ms_image2">
            <img src="../assets/benvenuti2.jpg" alt="" />
          </div>
          <div class="ms_image ms_image3">
            <img src="../assets/benvenuti3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Benvenuti-',
};
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap');

.ms_overlay{
  background: linear-gradient(180deg, rgba(237,191,113,1) 0%, rgb(238, 238, 238) 15%, rgb(255, 255, 255) 52%, rgb(255, 255, 255) 81%, rgb(255, 255, 255) 100%);
  /* background: linear-gradient(0deg, rgba(238, 191, 144, 0.816) 0%,rgba(255, 255, 255, 0.816) 50%, rgba(243, 223, 203, 0.816) 100%); */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.ms_container {
  position: relative;
}

.ms_stella-alpina{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.3;
  width: 700px;
  height: 700px;

  img{
    width: 100%;
    height: 100%;
  }
}

.ms_scritta {
  font-size: 30px;
}

.ms_scritta{
  font-family: 'Sansita Swashed', cursive;
  margin-bottom: 20px;
}

.ms_logo {
  font-family: "Pinyon Script", cursive;
  font-size: 40px;
  color: rgb(43, 141, 43);
  a {
    color: rgb(43, 141, 43);
    text-decoration: none;
  }
}

.ms_descrizione-hotel {
  margin-top: 10px;
  font-size: 20px;
  margin-bottom: 0;
  font-family: 'Roboto Slab', serif;
}

.ms_none{
  padding-top: 180px;
}

.ms_image {
  height: 300px;
  width: 300px;
  border: 5px solid white;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.ms_image2 {
  /* top: 210px; */
  top: 230px;
  /* right: -73px; */
  right: 0;
  position: absolute;
  width: 310px;
  height: 350px;
}

.ms_image3 {
  width: 250px;
  height: 250px;
  position: absolute;
  /* top: 278px; */
  bottom: 0;
  left: 30px;
  background-color: white;
  border-radius: 25px;
  img {
    border-radius: 20px;
  }
}

@media only screen and (min-width: 992px) {
  .ms_container {
    height: 100vh;
    min-height: 809px;
  }

  .ms_benvenuti{
    height: 100%
  }

  .ms_logo{
    font-size: 4rem;
  }

  .ms_stella-alpina{
    left: 28%;
  }
}

//cambio lingua
/* body:not(.it) :lang(it),  */
.lang-en, 
.lang-de { 
    display: none; 
}

.block{
  display: block!important;
}


</style>
