<template>
  <div class="ms_container">
    <div class="ms_table d-md-block d-lg-flex justify-content-center">
      <div class="ms_table-inverno">
        <div class="ms_title">
          <p>{{ $t('prezzo.titoloinverno') }}</p>
        </div>
        <div class="ms_subtitle">
          <!-- {{new Date().getFullYear()}} - {{new Date().getFullYear() + 1}} -->
          2024 - 2025
        </div>
        <div class="ms_persona">
          {{ $t('prezzo.sottotitolo') }}
        </div>
        <table>
          <tr>
            <th>
              {{ $t('prezzo.date') }}
            </th>
            <!-- <th class="d-none d-md-table-cell">
              {{ $t('prezzo.periodo') }}
            </th> -->
            <th>
              {{ $t('prezzo.camere') }}
            </th>
            <!-- <th>
              {{ $t('prezzo.pernottamento') }}
            </th> -->
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 30/11/24 {{ $t('prezzo.al') }} 20/12/24
            </td>
            <!-- <td class="d-none d-md-table-cell">
              {{ $t('prezzo.skipass') }} {{new Date().getFullYear()}}
            </td> -->
            <td>
              € 52,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 21/12/24 {{ $t('prezzo.al') }} 27/12/24
            </td>
            <td>
              € 52,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 28/12/24 {{ $t('prezzo.al') }} 10/01/25
            </td>
            <td>
              € 52,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 11/01/25 {{ $t('prezzo.al') }} 24/01/25
            </td>
            <td>
              € 49,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 25/01/25 {{ $t('prezzo.al') }} 28/02/25
            </td>
            <td>
              € 52,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 01/03/25 {{ $t('prezzo.al') }} 30/04/25
            </td>
            <td>
              € 52,00
            </td>
          </tr>
        </table>
      </div>


      <div class="ms_table-estate">
        <div class="ms_title">
          <p>{{ $t('prezzo.titoloestate') }}</p>
        </div>
        <div class="ms_subtitle">
          <!-- {{new Date().getFullYear()}} -->
          2024
        </div>
        <div class="ms_persona">
          {{ $t('prezzo.sottotitolo') }}
        </div>
        <table>
          <tr>
            <th>
              {{ $t('prezzo.date') }}
            </th>
            <th>
              {{ $t('prezzo.camere') }}
            </th>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 01/05/24 {{ $t('prezzo.al') }} 07/06/24
            </td>
            <td>
              € 41,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 08/06/24 {{ $t('prezzo.al') }} 12/07/24
            </td>
            <td>
              € 44,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 13/07/24 {{ $t('prezzo.al') }} 23/08/24
            </td>
            <td>
              € 46,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 24/08/24 {{ $t('prezzo.al') }} 06/09/24
            </td>
            <td>
              € 44,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 07/09/24 {{ $t('prezzo.al') }} 20/09/24
            </td>
            <td>
              € 41,00
            </td>
          </tr>
          <tr>
            <td>
              {{ $t('prezzo.dal') }} 21/09/24 {{ $t('prezzo.al') }} 29/11/24
            </td>
            <td>
              € 42,00
            </td>
          </tr>
          <!-- <tr>
            <td>
              {{ $t('prezzo.dal') }} 23/09/23 {{ $t('prezzo.al') }} 24/11/23
            </td>
            <td>
              € 41,00
            </td>
          </tr> -->
        </table>
      </div>
    </div>
    <!-- <div class="ms_table2 d-md-block d-lg-flex justify-content-center">
      <div class="ms_table-riduzioni">
        <div class="ms_title">
          {{ $t('prezzo.riduzioni') }}
          </div>
          <table>
            <tr>
              <th>
                {{ $t('prezzo.descrizione') }}
              </th>
              <th>
                {{ $t('prezzo.sconto') }}
              </th>
            </tr>
            <tr>
              <td>
                Terzo letto
              </td>
              <td>
                10%
              </td>
            </tr>
            <tr>
              <td>
                Bambini da 0 a 2 anni in camera con i genitori
              </td>
              <td>
                80%
              </td>
            </tr>
            <tr>
              <td>
                Bambini da 2 a 5 anni in camera con i genitori
              </td>
              <td>
                40%
              </td>
            </tr>
            <tr>
              <td>
                Bambini da 6 a 12 anni in camera con i genitori
              </td>
              <td>
                20%
              </td>
            </tr>
          </table>
      </div>
      <div class="ms_table-supplementi">
        <div class="ms_title">
            {{ $t('prezzo.supplementi') }}
          </div>
          <table>
            <tr>
              <th>
                {{ $t('prezzo.descrizione') }}
              </th>
              <th>
                {{ $t('prezzo.supplemento') }}
              </th>
            </tr>
            <tr>
              <td>
                Suite camera superior (per 2 pers.)
              </td>
              <td>
                50%
              </td>
            </tr>
            <tr>
              <td>
                Suite camera superior (per 3 pers.)
              </td>
              <td>
                15%
              </td>
            </tr>
            <tr>
              <td>
                Suite camera superior (per 4 pers.)
              </td>
              <td>
                Stesso prezzo
              </td>
            </tr>
            <tr>
              <td>
                Singola
              </td>
              <td>
                € 10,00
              </td>
            </tr>
            <tr>
              <td>
                Camera doppia uso singola
              </td>
              <td>
                50%
              </td>
            </tr>
          </table>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'Price-',
}


</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap');

.ms_container{
  width: 100%;
  overflow-x: hidden;
  margin-top: 50px;
  min-height: calc(100vh - 100px);
}

.ms_table-inverno, .ms_table-estate, .ms_table-riduzioni, .ms_table-supplementi{
  padding: 20px;
}

.ms_title{
  text-transform: uppercase;
  font-family: 'Roboto Slab', serif;
}

.ms_subtitle{
  color: rgb(63, 162, 63);
  font-family: 'Roboto Slab', serif;
}

.ms_persona{
  background-color: rgb(63, 162, 63);
  width: 250px;
  padding: 5px;
  text-align: center;
  margin: 10px 0;
  color: white;
  font-family: 'Roboto Slab', serif;
}

table{
  margin: 20px auto;
  width: 90vw;
  font-family: 'Roboto Slab', serif;
}

th{
  background-color: rgb(227, 203, 173);
  color:black;
}

td, th{
  padding: 10px;
}

td{
  color: #7D7D7D;
}

tr:nth-child(odd){
  background-color: rgb(252, 249, 249);
}

@media only screen and (max-width: 300px) {
  .ms_persona{
    width: 100%;
  }

  tr{
    font-size: 10px;
  }
}

@media only screen and (min-width: 992px) {
  table{
    width: 40vw;
  }
}
</style>
