import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Price from '../views/Price.vue'
import Room from '../views/Room.vue'
import Restaurant from '../views/Restaurant.vue'
import Apartment from '../views/Apartment.vue'
import Livigno from '../views/Livigno.vue'

/* import i18n from './i18n' */

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/it'
    },
    {
        path: '/:lang',
        component: {
            render (c) { return c('router-view')}
        },
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: 'Price',
                name: 'Price',
                component: Price
            },
            {
                path: 'Room',
                name: 'Room',
                component: Room
            },
            {
                path: 'Restaurant',
                name: 'Restaurant',
                component: Restaurant
            },
            {
                path: 'Apartment',
                name: 'Apartment',
                component: Apartment
            },
            {
                path: 'Livigno',
                name: 'Livigno',
                component: Livigno
            },
            /* {
                path: '*',
                name: "page-404",
                component: PageNotFound,
                redirect: '404'
            } */
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router