<template>
  <div>
    <Carousel />
    <Benvenuti />
    <Camere />
    <!-- <Servizi /> -->
    <Contatti />
  </div>
</template>

<script>
import Carousel from "../components/Carousel.vue";
import Benvenuti from "../components/Benvenuti.vue";
import Camere from "../components/Camere.vue";
/* import Servizi from "../components/Servizi.vue"; */
import Contatti from "../components/Contatti.vue";




export default {
  name: 'App',
  components: {
    Carousel,
    Benvenuti,
    Camere,
    /* Servizi, */
    Contatti
  }
}
</script>

<style lang="scss">
*{
  border: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}
</style>
