<template>
<div>

  <div class="ms_container">
    <div class="ms_scritta text-center text-sm-left">
      <div class="ms_title">
        {{ $t('livigno.titolo') }}
      </div>
      <div class="ms_subtitle">
        {{ $t('livigno.sottotitolo') }}
      </div>
    </div>
    <div class="row row-cols-1 row-cols-sm-2 row-cols-lg-1 ms_row">
      <div class="col p-sm-0">
        <div class="ms_cardinverno">
          <div class="ms_overlayblu">
            <div class="ms_scritteinverno">
              <div class="ms_titleinverno">
                {{ $t('livigno.inverno') }}
              </div>
              <div class="ms_subtitleinverno">
                <a href="#inverno" @click="isActive = true">
                  <i class="fas fa-arrow-down"></i>
                  {{ $t('livigno.scopri') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="ms_cardestate">
          <div class="ms_overlayrosso">
            <div class="ms_scritteestate text-sm-right">
              <div class="ms_titleestate">
                {{ $t('livigno.estate') }}
              </div>
              <div class="ms_subtitleestate">
                <a href="#estate" @click="isActive2 = true">
                  <i class="fas fa-arrow-down"></i> 
                  {{ $t('livigno.scopri') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ms_descrizione text-center">
      {{ $t('livigno.descrizione') }}
      <span class="ms_hidden">
        {{ $t('livigno.descrizioneHidden') }}
      </span>
    </div>
  </div>
  <!-- attività in inverno -->
  <div class="container mb-5">
    <div id="inverno" class="ms_none" v-bind:class="{ active: isActive }">
      <div class="d-flex align-items-center"> 
        <span class="ms_x" @click="isActive = false">
          <i class="fa-solid fa-xmark"></i>
        </span>
        <div class="ms_title2 p-3 text-uppercase">
          {{ $t('livigno.attivitàInvernaliTitolo') }}
          <br>
          {{ $t('livigno.cosafare') }}
        </div>
      </div>
      <div class="ms_rowcarousel row row-cols-1 row-cols-md-2">
        <div class="col ms_carousel">

          <carousel :perPage="1" :autoplay="true" :loop="true" :paginationEnabled="false"  :navigationEnabled="false">
            <slide class="ms_slide">
              <img src="../assets/sci alpino.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali1') }}</h1>
            </slide>
            <!-- <slide class="ms_slide ms_slidefondo">
              <img src="../assets/sci fondo.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali2') }}</h1>
            </slide> -->
            <slide class="ms_slide">
              <img src="../assets/snowboard1.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali3') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/alpinismo.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali4') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/ciaspole.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali5') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/pattinaggio ghiaccio.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali6') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/alpinismo3.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali7') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/freestyle.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali8') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/shopping.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali9') }}</h1>
            </slide>
            <!-- <slide class="ms_slide">
              <img src="../assets/sci alpino.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali10') }}</h1>
            </slide> -->
            <!-- <slide class="ms_slide">
              <img src="../assets/nordic walking.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali11') }}</h1>
            </slide> -->
            <!-- <slide class="ms_slide">
              <img src="../assets/spa.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali12') }}</h1>
            </slide> -->
            <!-- <slide class="ms_slide">
              <img src="../assets/sci alpino.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali13') }}</h1>
            </slide> -->
            <slide class="ms_slide ms_slidecascata">
              <img src="../assets/arrampicata ghiaccio.jpg" alt="">
              <h1>{{ $t('livigno.attivitàInvernali14') }}</h1>
            </slide>
          </carousel>
        </div>
        <div class="col ms_colinverno">
          <div class="ms_background d-flex align-items-center text-center">
            {{ $t('livigno.attivitàInvernali') }}
          </div>
        </div>
      </div>
    </div>


          <!-- <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            img-width="1024"
            img-height="480!importnat"
            
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            
            <b-carousel-slide class="carouselimg" :img-src="require('../assets/sci alpino.jpg')">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali1') }}</h1>
                <p>{{ $t('livigno.descrizioneCarosello') }}</p>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali2') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" :img-src="require('../assets/snowboard.jpg')">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali3') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" :img-src="require('../assets/alpinismo.jpg')">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali4') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali5') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali6') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali7') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali8') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali9') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali10') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali11') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali12') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali13') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali14') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide class="carouselimg" img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàInvernali15') }}</h1>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
        <div class="col ms_colinverno">
          <div class="ms_background d-flex align-items-center text-center">
            {{ $t('livigno.attivitàInvernali') }}
          </div>
        </div>
      </div>
    </div> -->


    <div id="estate" class="ms_none" v-bind:class="{ active: isActive2 }">
      <div class="d-flex text-right justify-content-end align-items-center">
        <div class="ms_title2 p-3 text-uppercase">
          {{ $t('livigno.attivitàEstiveTitolo') }}
          <br>
          {{ $t('livigno.cosafare') }}
        </div>
        <span class="ms_x" @click="isActive2 = false, scrollToTop" >
          <i class="fa-solid fa-xmark"></i>
        </span>
      </div>
      <div class="ms_rowcarousel row row-cols-1 row-cols-md-2">
        <div class="col ms_colestate">
          <div class="ms_background d-flex align-items-center text-center">
            {{ $t('livigno.attivitàEstive') }}
          </div>
        </div>

        <div class="col ms_carousel">

          <carousel :perPage="1" :autoplay="true" :loop="true" :paginationEnabled="false"  :navigationEnabled="false">
            <slide class="ms_slide">
              <img src="../assets/shopping.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive1') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/hiking.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive2') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/downhill.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive3') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/lago.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive4') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/corsa.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive5') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/golf1.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive6') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/pesca.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive7') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/arrampicata.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive8') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/larix park.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive9') }}</h1>
            </slide>
            <!-- <slide class="ms_slide">
              <img src="../assets/sci alpino.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive10') }}</h1>
            </slide> -->
            <slide class="ms_slide">
              <img src="../assets/nuoto.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive11') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/palestra.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive12') }}</h1>
            </slide>
            <slide class="ms_slide">
              <img src="../assets/funghi.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive13') }}</h1>
            </slide>
            <!-- <slide class="ms_slide">
              <img src="../assets/nordic walking.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive14') }}</h1>
            </slide> -->
            <!-- <slide class="ms_slide ms_slidecascata">
              <img src="../assets/arrampicata ghiaccio.jpg" alt="">
              <h1>{{ $t('livigno.attivitàEstive15') }}</h1>
            </slide> -->
          </carousel>


          <!-- <b-carousel
            id="carousel-2"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            img-width="1024"
            img-height="480"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
          >
            
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive1') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive2') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive3') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive4') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive5') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive6') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive7') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive8') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive9') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive10') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive11') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive12') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive13') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive14') }}</h1>
              </div>
            </b-carousel-slide>
            <b-carousel-slide img-src="https://picsum.photos/1024/480/?image=52">
              <div class="carousel-text">
                <h1>{{ $t('livigno.attivitàEstive15') }}</h1>
              </div>
            </b-carousel-slide>
          </b-carousel> -->
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Livigno-',
  data() {
      return {
        slide: 0,
        sliding: null,
        isActive: false,
        isActive2: false
      }
    },
    methods: {
      onSlideStart() {
        this.sliding = true
      },
      onSlideEnd() {
        this.sliding = false
      },
      scrollToTop() {
        window.scrollTo(0,0);
      }
    },
}; 
</script>


<style scoped lang="scss">

//carosello
.ms_title2{
  font-family: 'Roboto Slab', serif; 
}

.ms_rowcarousel{
  background-color: #FBFAF9;
}

.ms_slide{
  position: relative;
  height: 400px;
}

h1{
  font-family: 'Roboto Slab', serif;
}

.VueCarousel{
  height: 100%;
}

.ms_carousel{
  padding: 0;
}

.ms_carousel img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.ms_carousel h1{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}




.ms_container{
  position: relative;
  overflow: hidden;
  background-color: #FBFAF9;
  min-height: 100vh;
}

.ms_scritta{
  width: 100%;
  border-top: 50px solid white;
}

.ms_scritta{
  font-family: 'Sansita Swashed', cursive;
  font-size: 25px;
}

.ms_overlayblu{
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(55,133,188,0.8127626050420168) 0%, rgba(255,255,255,0) 60%);
  position: relative; 
}

.ms_scritteinverno, .ms_scritteestate{
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  font-family: 'Sansita Swashed', cursive;  
}

.ms_titleinverno, .ms_titleestate{
  text-transform: uppercase;
}

.ms_subtitleinverno, .ms_subtitleestate{
  a{
    color: white;
    text-decoration: underline;
    font-family: 'Roboto Slab', serif;
  }
  a:hover{
    color: black;
  }
}

.ms_cardinverno, .ms_cardestate{
  width: 100%;
  height: 250px;
  background-size: cover;
  margin-bottom: 10px;
  background-position: center;
}

.ms_cardinverno{
  background-image: url(../assets/sci.jpg);
}

.ms_cardestate{
  background-image: url(../assets/bici.jpg);
  background-position: bottom;
}

.ms_overlayblu{
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(55,133,188,0.8127626050420168) 0%, rgba(255,255,255,0) 60%);
  position: relative;
}

.ms_overlayrosso{
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(209,161,114,0.8155637254901961) 100%);
  position: relative;
  margin-bottom: 110px;
}

.ms_descrizione{
  padding: 20px;
  height: 100%;
  /* border-bottom: 20px solid white; */
  font-family: 'Roboto Slab', serif;
}

.ms_colinverno{
  /* margin: 0 15px; */
  min-height: 239px;
  position: relative;
}

.ms_colestate{
  min-height: 239px;
}

.ms_background{
  /* background-color: #FBFAF9;
  height: 239px; */
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Roboto Slab', serif;
}

.ms_none{
  display: none;
}

.active{
  display: block;
}

.ms_x{
  height: 30px;
  cursor: pointer;
  border: 3px solid green;
  border-radius: 100%;
  padding: 0 7px;
  color: green;
}

.ms_x:hover{
  color: brown;
  border-color: brown;
}

.ms_slidefondo{
  img{
    object-position: 0 -290px;
  }
}

.ms_slidecascata{
  img{
    object-position: top;
  }
}

@media only screen and (min-width: 576px) {
  .ms_container{
    padding-bottom: 50px;
  }

  .ms_row{
    height: 400px;
  }

  .ms_scritta{
    position: absolute;
    left: 50%;
    padding: 50px;
  }

  .ms_cardinverno{
    height: 500px;
  }

  .ms_cardestate{
    position: absolute;
    right: 0;
    top: 400px;
    height: 500px;
  }

  .ms_overlayrosso{
    margin-bottom: 0px;
  }

  .ms_scritteinverno{
    left: 40px;
  }

  .ms_scritteestate{
    top: 20px;
    right: 40px;
  }

  .ms_descrizione{
    width: 50%;
    display: flex;
    align-items: center;
    padding-top: 100px;
  }

  .ms_hidden{
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .ms_carousel{
    padding-right: 0;
  }

  .ms_carousel2{
    padding-left: 0;
  }

  .ms_colinverno{
    padding-left: 0;
  }

  .ms_colestate{
    padding-right: 0;
  }

  /* .ms_background {
    height: 164px;
  } */
}

@media only screen and (min-width: 992px) {
  .ms_cardinverno{
    width: 55%;
    height: 700px;
  }

  .ms_row{
    height: 100%;
  }

  .ms_cardestate{
    width: 55%;
    top: -200px;
    border-left: 10px solid #FBFAF9;
    border-top: 10px solid #FBFAF9;
    height: 700px;
  }

  .ms_scritta{
    left: 60%;
    padding-top: 50px;
    padding-left: 100px;
  }
  
  .ms_descrizione{
    width: 45%;
    display: block;
    /* border-bottom: 330px solid white; */
    padding: 50px;
    height: 500px;
  }

  .ms_hidden{
    display: block;
  }

  //parte 2
  /* .ms_background {
    height: 219px;
  } */
}

@media only screen and (min-width: 1200px) {
  /* .ms_background {
    height: 260px;
  } */
}
</style>
