<template>
<div>
  <div id="camere" class="ms_camere">
    <div class="ms_container2 d-flex align-items-center py-3">
      <div class="ms_colum-rev row row-cols-1 row-cols-xl-2">
        <div class="col mt-5 mt-lg-0" data-aos="fade-right" data-aos-duration="2000">
          <div class="ms_img-camere ms_img-grande">
            <img src="../assets/camera1.jpg" alt="" />
          </div>
          <div class="d-flex">
            <div
              class="
                ms_img-camere ms_img-sfondo ms_img-media
                overflow-auto
                d-flex
                flex-column
                justify-content-around
              "
            >
              <div class="ms_intermezzo">
                <div class="ms_descrizionecamere text-center">
                  {{ $t('camere.titolo') }}
                </div>
              </div>
              <!-- <div class="ms_servizi-camere d-flex justify-content-between">
                <div class="ms_icona ms_letto">
                  <i class="fas fa-bed"></i>
                </div>
                <div class="ms_icona ms_doccia">
                  <i class="fa-solid fa-shower"></i>
                </div>
                <div class="ms_icona ms_tv">
                  <i class="fas fa-tv"></i>
                </div>
                <div class="ms_icona ms_wifi">
                  <i class="fas fa-wifi"></i>
                </div>
              </div> -->
            </div>
            <div class="ms_img-camere ms_img-piccola">
              <img src="../assets/armadio-camera.jpg" alt="" />
            </div>
          </div>
        </div>

        <div class="col" data-aos="fade-left" data-aos-duration="2000">
          <div class="d-flex pt-3 p-xl-0">
            <div class="
                ms_img-camere ms_img-sfondo ms_img-piccola
                d-flex
                flex-column
                justify-content-center
                text-center">
              <div class="ms_intermezzo">
                {{ $t('camere.titolo2') }}
              </div>
            </div>
            <div class="ms_img-camere ms_img-media">
              <img src="../assets/colazione3.jpg" alt="" />
            </div>
          </div>
          <div class="d-flex">
            <div class="ms_img-camere ms_img-media">
              <img src="../assets/parcheggio.jpg" alt="" />
            </div>
            <div class="ms_img-camere ms_img-piccola">
              <img src="../assets/depositosci.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ms_bgbusta">
    <div class="image--left"></div>
    <div class="image--right"></div>
    <div class="ms_posta"></div>
    <div class="ms_postalinea">
    </div>
    <router-link :to="`/${$i18n.locale}/Room`" exact>
      <div class="ms_bustascritta">
        {{ $t('camere.scopri') }}
      </div>
    </router-link>
  </div>
</div>
</template>

<script>
export default {
  name: 'Camere-',
};
</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed&display=swap');

.ms_camere {
  background-color: #9ac49a;
  padding: 50px 0;
  margin: 0;
  background-position: center;
}

.ms_container2 {
  position: relative;
  height: 50vh;
  width: 100vw;
}

.ms_img-camere {
  border: 5px solid #9ac49a;
  font-family: 'Sansita Swashed', cursive;
  font-size: 30px;
  overflow: hidden;
  
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;

    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
    filter: brightness(110%);
  }
  
  img:hover{
    transform: scale(1.5);
    filter: brightness(100%);
  }
}


.ms_img-sfondo {
  background-color: white;
  padding: 10px;
}

.ms_img-grande {
  width: 100%;
  height: 26vh;
  border: 2px solid orange;
}

.ms_img-media {
  width: 60%;
  height: 26vh;
  border: 2px solid orange;
}

.ms_img-piccola {
  width: 40%;
  height: 26vh;
  border: 2px solid orange;
}

.ms_icona {
  padding: 10px 0;
  color: rgb(43, 141, 43);
  i {
    border: 2px solid rgb(43, 141, 43);
    border-radius: 100%;
    padding: 10px;
  }
}

.ms_intermezzo {
  text-decoration: underline rgba(220, 147, 83, 0.975);
}

.ms_colum-rev {
  width: 100vw;
  margin: 0 auto;
}

.ms_bgbusta{
  position: relative;
  width: 100%;
  height: 500px;
  display: flex;
  /* border: 15px solid #f7f4f0; */
  border-top: 0;
  z-index: 4;
  background-color: #f7f4f0;
}

.ms_posta{
  width: 100%;
  height: 250px;
  border-left: 50vw solid transparent;
  border-right: 50vw solid transparent;
  border-top: 170px solid #9ac49a;
  z-index: 2;
}

.ms_postalinea{
  width: 90%;
  height: 250px;
  border-left: 45vw solid transparent;
  border-right: 45vw solid transparent;
  border-top: 150px solid rgba(66, 135, 84, 0.527);
  position: absolute;
  top: 0;
  left: 0;
  /* margin: 0 127px; */
  margin: 0 5%;
  z-index: 2;
}

.ms_bustascritta{
  font-size: 25px;
  background-color: white;
  padding: 5px 10px;
  border: 3px solid #9ac49a;
  position: absolute;
  left: 50%;
  top: 8%;
  transform: translate(-50%, 0);
  z-index: 2;
  color: orange;
  font-family: 'Roboto Slab', serif;
}

.ms_bustascritta:hover{
  background-color:  rgb(230, 199, 142);
  color: white;
}

//immagini tagliate busta
.image {
  align-items: center;
  display: flex;
  height: 600px;
  justify-content: center;
  margin-bottom: 24px;
  max-height: 802px;
  position: relative;
  
  &--left {
    background-image: url(../assets/salacv8.jpg);
    background-position: -200px center;
    background-repeat: no-repeat;
    background-size: 100%;
    /* background-size: 100%; */
    -webkit-clip-path: polygon(0 0, 57% 0, 45% 100%, 0% 100%);
    clip-path: polygon(0 0, 57% 0, 45% 100%, 0% 100%);
    height: 100%;
    position: absolute;
    width: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
  
  &--right {
    background-image: url(../assets/comodino.jpg);
    background-position: right 50px;
    background-repeat: no-repeat;
    background-size: 60%;
    -webkit-clip-path: polygon(58% 0, 100% 0, 100% 100%, 46% 100%);
    clip-path: polygon(58% 0, 100% 0, 100% 100%, 46% 100%);
    height: 100%;
    position: absolute;
    width: 100%;
    background-attachment: fixed;
    background-repeat: no-repeat;
  }
}


@media only screen and (max-width: 1200px) {
  .ms_container2 {
    height: unset;
  }

  .ms_camere {
    padding: 0px 0;
  }

  .ms_colum-rev {
    flex-direction: column-reverse !important;
    margin: 0;
    width: 100vw;
  }

  .ms_posta{
    width: 100%;
    height: 250px;
    border-left: 45vw solid transparent;
    border-right: 45vw solid transparent;
    border-top: 150px solid #9ac49a;
  }

  .ms_postalinea{
    width: 90%;
    height: 250px;
    border-left: 40vw solid transparent;
    border-right: 40vw solid transparent;
    border-top: 130px solid rgba(66, 135, 84, 0.527);
  }

  .ms_bustascritta{
    top: 5vw;
    font-size: 20px;
  }

  .ms_bgbusta{
    height: 250px;
    background-color: rgba(66, 135, 84, 0.527);
  }

  .image {
    
    &--left {
      background-size: 95%;
      background-color: rgba(66, 135, 84, 0.527);
      background-image: unset;
      clip-path: polygon(0 0, 58% 0, 46% 100%, 0% 100%);
    }
    
    &--right {
      background-size: 60%;
      background-color: rgba(66, 135, 84, 0.527);
      background-image: unset;
    }
  }
}

@media only screen and (max-width: 355px) {
  .ms_bustascritta{
    font-size: 15px;
  }

  .ms_intermezzo {
    font-size: 22px;
  }

  .ms_bgbusta{
    height: 170px;
  }

  .ms_posta{
    border-top: 115px solid #9ac49a;
  }

  .ms_postalinea{
    border-top: 100px solid rgba(66, 135, 84, 0.527);
  }

  .ms_bustascritta{
    top: 2.5vw;
  }
}
</style>
