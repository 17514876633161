import Vue from 'vue'
import App from './App.vue'

import router from './router'

import AOS from 'aos'
import 'aos/dist/aos.css'


import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@fortawesome/fontawesome-free/css/all.css'
import i18n from './i18n'


Vue.component('font-awesome-icon', FontAwesomeIcon)




Vue.config.productionTip = false

router.beforeEach((to, from, next) => {

  let language = to.params.lang;
  if (!language) {
    language = 'it'
  }

  i18n.locale = language
  next()
})



new Vue({
  created () {
    AOS.init()
  },

  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
