<template>
  <div class="ms_container overflow-hidden">
    <div class="ms_title text-center">
      {{ $t('camere.titoloroom') }}
    </div>
    <div class="ms_mosaico">
      <div class="row row-cols-1 row-cols-md-3">
        <div class="col">
          <div class="ms_imggrande">
            <img src="../assets/camera4.jpg" alt="">
          </div>
        </div>
        <div class="ms_col2 col">
          <div class="d-flex">
            <div class="ms_imgpiccola1">
              <img src="../assets/camera2.jpg" alt="">
            </div>
            <div class="ms_imgpiccola2">
              <img src="../assets/fiore.jpg" alt="">
            </div>
          </div>
          <div class="ms_imgpiccola3">
            <img src="../assets/salacv14.jpg" alt="">
          </div>
        </div>
        <div class="col">
          <div class="ms_imggrande">
            <img src="../assets/colazione4.jpg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="row ms_bottom">
      <div class="container col-12 col-md-8 position-relative">
        <img src="../assets/stella alpina new orange.svg" alt="">
        <div class="ms_descrizione text-center">
          {{ $t('camere.descrizione1') }}
          <br>
          {{ $t('camere.descrizione2') }}
          <br>
          {{ $t('camere.descrizione3') }}
        </div>
        <div class="ms_servizi text-center my-0 my-md-5">
          <div class="ms_servizititolo mb-5">
            {{ $t('camere.titoloservizi') }}
          </div>
          <div class="row row-cols-1 row-cols-md-4 mt-3">
            <div class="col ms_borderr">
              <i class="fas fa-bed"></i>
              <div class="ms_testo">
                {{ $t('camere.servizi1') }}
                <div class="ms_border"></div>
              </div>
            </div>
            <div class="col ms_borderr">
              <i class="fas fa-shower"></i>
              <div class="ms_testo">
                {{ $t('camere.servizi2') }}
                <div class="ms_border"></div>
              </div>
            </div>
            <div class="col ms_borderr">
              <i class="fas fa-wind"></i>
              <div class="ms_testo">
                {{ $t('camere.servizi3') }}
                <div class="ms_border"></div>
              </div>
            </div>
            <div class="col">
              <i class="fa-solid fa-tv"></i>
              <div class="ms_testo pb-0 ">
                {{ $t('camere.servizi4') }}
                <div class="ms_border1"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ms_servizidestra container col-12 col-md-4 pb-5 pb-md-0">
        <div class="ms_altrotitolo text-center">
          {{ $t('camere.altriservizi') }}
        </div>
        <div class="ms_cardservizi">
          <div class="p-2">
            <i class="fas fa-skiing"></i>
            <span class="ml-5">{{ $t('camere.servizi5') }}</span>
          </div>
          <div class="p-2">
            <i class="fas fa-bicycle"></i>
            <span class="ml-5">{{ $t('camere.servizi6') }}</span>
          </div>
          <div class="p-2">
            <i class="fas fa-parking"></i>
            <span class="ml-5">{{ $t('camere.servizi7') }}</span>
          </div>
          <div class="p-2">
            <i class="fas fa-sun"></i>
            <span class="ml-5">{{ $t('camere.servizi8') }}</span>
          </div>
          <div class="p-2">
            <i class="fas fa-wifi"></i>
            <span class="ml-5">{{ $t('camere.servizi9') }}</span>
          </div>
          <div class="p-2">
            <i class="fas fa-bus"></i>
            <span class="ml-5">{{ $t('camere.servizi10') }}</span>
          </div>
          <div class="p-2  skipass-free text-center">
            <img src="../assets/skipass-free.webp" alt="">
            <!-- <span class="ml-5">{{ $t('camere.servizi11') }}</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Room-',
}


</script>


<style scoped lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

.ms_container{
  width: 100%;
  overflow-x: hidden;
  margin-top: 50px;
  min-height: 100vh;
}

.ms_title{
  font-size: 30px;
  margin-bottom: 30px;
  font-family: 'Sansita Swashed', cursive;
}

.ms_imggrande{
  height: 300px;
  /* min-width: 300px; */
  /* background-color: aqua; */
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;

    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
    filter: brightness(110%);
  }

  img:hover{
    transform: scale(1.5);
    filter: brightness(100%);
  }
}

.ms_col2{
  width: 100%;
  height: 300px;
  margin: 30px 0;
}

.ms_imgpiccola1, .ms_imgpiccola2, .ms_imgpiccola3{
  /* background-color: rgb(156, 254, 156); */
  height: 135px;
  overflow: hidden;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    transform-origin: 50% 65%;
    transition: transform 5s, filter 3s ease-in-out;
    filter: brightness(110%);
  }

  img:hover{
    transform: scale(1.5);
    filter: brightness(100%);
  }
}

.ms_imgpiccola1, .ms_imgpiccola2{
  width: 50%;
}

.ms_imgpiccola1{
  margin-right: 15px;
  img{
    object-position: bottom;
  }
}

.ms_imgpiccola2{
  margin-left: 15px;
  img{
    object-position: center;
  }
}

.ms_imgpiccola3{
  width: 100%;
  margin-top: 30px;
  img{
    object-position: center;
  }
}

.ms_bottom{
  padding-top: 20px;
}

.ms_testo{
  text-transform: uppercase;
  padding: 20px;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
}

.ms_descrizione{
  font-family: 'Roboto Slab', serif;
}

.ms_servizititolo{
  font-family: 'Roboto Slab', serif;
}

.ms_border{
  border-bottom: 1px solid brown;
  width: 200px;
  margin: 0 auto;
  margin-top: 20px;
}

.ms_border1{
  border-bottom: 3px solid brown;
  margin-top: 20px;
}

.ms_altrotitolo{
  font-size: 25px;
  margin-bottom: 30px;
  font-family: 'Sansita Swashed', cursive;
  margin-top: 10px;
}

.ms_servizidestra{
  background-color: #f7f4f0;
}

.ms_servizidestra i{
  font-size: 30px;
  color: rgb(61, 143, 61);
  width: 30px;
}

.ms_cardservizi{
  width: 300px;
  margin: 0 auto;
  span{
    font-weight: bold;
  }
}

.p-2 img{
  height: 30px;
  width: 30px;
  margin-bottom: 20px;
}
.skipass-free{
  img{
    height: 70px;
    width: 70px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
}

.container:first-child img{
  position: absolute;
  height: 1300px;
  top: -250px;
  left: -500px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(20deg); */
  transform: rotate(30deg); 
  opacity: 0.3;
}


@media only screen and (min-width: 767px) {
  .ms_imggrande{
    height: 500px;
  }

  .ms_col2{
    margin-top: 0;
    height: 500px;
  }

  .ms_imgpiccola1, .ms_imgpiccola2, .ms_imgpiccola3{
    height: 235px;
  }

  .ms_border{
    border-bottom: 0;
    margin-bottom: 0;
  }

  .ms_borderr{
    border-right: 1px solid brown;
  }

  .ms_border1{
    border-bottom: 0px solid brown;
    margin-top: 20px;
  }

  .ms_servizidestra{
    border-top: 2px solid brown;
    border-left: 2px solid brown;
  }
}
@media only screen and (min-width: 1800px) {
  .ms_mosaico{
    height: 50vh;
  }

  .ms_bottom{
    height: 50vh;
  }
}
</style>
